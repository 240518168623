body {
  margin: 0;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #656565;
}

code {
  font-family: 'IBM Plex Mono', 'Courier New', monospace;
}
